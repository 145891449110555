import React from "react";
import { FormErrorMessage } from "components";

const AppInput = ({
  label,
  name,
  placeholder,
  register,
  error,
  rules,
  type = "text",
  className = "",
  ...props
}) => {
  return (
    <div className={`w-full ${className}`}>
      <label
        htmlFor={name}
        className={`mb-1.5 block w-full text-sm font-medium ${
          error ? "text-red-600" : "text-gray-900"
        }`}
      >
        {label}
      </label>
      <input
        type={type}
        id={name}
        className={`block w-full rounded-3xl border bg-white px-4 py-3 text-sm text-gray-700 focus:border-yellow-500 focus:ring-yellow-500 ${
          error
            ? "border-red-500 focus:border-red-500"
            : "border-gray-300 focus:border-gray-700"
        }`}
        placeholder={placeholder}
        {...register(name, rules)}
        {...props}
      />
      <FormErrorMessage label={label} error={error} />
    </div>
  );
};

export default AppInput;
