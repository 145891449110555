import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="flex flex-col py-10">
      <h1 className="mb-4 font-display text-2xl font-medium text-gray-950">
        Gizlilik ve Veri Koruma Politikası
      </h1>
      <h2 className="mb-2 font-display text-lg font-semibold text-gray-800">
        1. Giriş
      </h2>
      <p className="mb-4 text-base leading-7 text-gray-700">
        Lexgine ("Şirket"), kullanıcılarının gizliliğine ve kişisel verilerinin
        korunmasına büyük önem verir. Bu Gizlilik ve Veri Koruma Politikası,
        Lexgine Yapay Zeka Asistanı ("Hizmet") aracılığıyla toplanan kişisel
        verilerin nasıl toplandığını, kullanıldığını ve korunduğunu
        açıklamaktadır. Hizmeti kullanarak bu politikayı kabul etmiş olursunuz.
      </p>

      <h2 className="mb-2 font-display text-lg font-semibold text-gray-800">
        2. Toplanan Bilgiler
      </h2>
      <p className="mb-4 text-base leading-7 text-gray-700">
        2.1. <strong>Kullanıcı Bilgileri:</strong> Hizmeti kullanırken adınız,
        e-posta adresiniz, telefon numaranız gibi kişisel bilgileri
        toplayabiliriz.
      </p>
      <p className="mb-4 text-base leading-7 text-gray-700">
        2.2. <strong>Kullanım Bilgileri:</strong> Hizmeti nasıl kullandığınıza
        dair veriler (örn. IP adresi, tarayıcı türü, erişim süreleri ve
        tarihleri) toplanabilir.
      </p>
      <p className="mb-4 text-base leading-7 text-gray-700">
        2.3. <strong>Hizmet Verileri:</strong> Hizmet aracılığıyla sağlanan ve
        alınan veriler, yasal işlemler ve belgelerle ilgili bilgiler içerebilir.
      </p>

      <h2 className="mb-2 font-display text-lg font-semibold text-gray-800">
        3. Bilgilerin Kullanımı
      </h2>
      <p className="mb-4 text-base leading-7 text-gray-700">
        3.1. Toplanan bilgileri şu amaçlarla kullanabiliriz:
      </p>
      <ul className="mb-4 list-disc pl-4 text-base leading-7 text-gray-700">
        <li>Hizmetin sağlanması ve iyileştirilmesi,</li>
        <li>Kullanıcı desteği sağlanması,</li>
        <li>Hizmetle ilgili bildirimler gönderilmesi,</li>
        <li>Hizmetin kullanımını analiz ederek performansın artırılması,</li>
        <li>Yasal yükümlülüklerin yerine getirilmesi.</li>
      </ul>

      <h2 className="mb-2 font-display text-lg font-semibold text-gray-800">
        4. Bilgilerin Paylaşımı
      </h2>
      <p className="mb-4 text-base leading-7 text-gray-700">
        4.1. Kişisel bilgilerinizi, aşağıdaki durumlar dışında üçüncü şahıslarla
        paylaşmayız:
      </p>
      <ul className="mb-4 list-disc pl-4 text-base leading-7 text-gray-700">
        <li>Kullanıcı onayı ile,</li>
        <li>Yasal zorunluluk durumunda,</li>
        <li>Şirket birleşmesi, devralma veya varlık satışı gibi durumlarda,</li>
        <li>
          Hizmeti sağlamak amacıyla hizmet sağlayıcılarla (bu durumda,
          sağlayıcıların bilgileri koruma yükümlülükleri olacaktır).
        </li>
      </ul>

      <h2 className="mb-2 font-display text-lg font-semibold text-gray-800">
        5. Veri Güvenliği
      </h2>
      <p className="mb-4 text-base leading-7 text-gray-700">
        5.1. Kişisel bilgilerinizin güvenliğini sağlamak için uygun teknik ve
        idari önlemleri alıyoruz. Ancak, internet üzerinden yapılan hiçbir veri
        iletimi veya depolama yönteminin tamamen güvenli olmadığını unutmayın.
      </p>

      <h2 className="mb-2 font-display text-lg font-semibold text-gray-800">
        6. Çerezler ve Benzeri Teknolojiler
      </h2>
      <p className="mb-4 text-base leading-7 text-gray-700">
        6.1. Hizmet, kullanıcı deneyimini geliştirmek için çerezler ve benzeri
        izleme teknolojileri kullanabilir. Tarayıcı ayarlarını değiştirerek
        çerez kullanımını reddedebilirsiniz, ancak bu durumda Hizmetin bazı
        özellikleri düzgün çalışmayabilir.
      </p>

      <h2 className="mb-2 font-display text-lg font-semibold text-gray-800">
        7. Üçüncü Taraf Bağlantıları
      </h2>
      <p className="mb-4 text-base leading-7 text-gray-700">
        7.1. Hizmet, üçüncü taraf web sitelerine veya hizmetlerine bağlantılar
        içerebilir. Bu üçüncü tarafların gizlilik uygulamalarından sorumlu
        değiliz. Bu sitelerin gizlilik politikalarını incelemenizi öneririz.
      </p>

      <h2 className="mb-2 font-display text-lg font-semibold text-gray-800">
        8. Erişim ve Düzeltme Hakları
      </h2>
      <p className="mb-4 text-base leading-7 text-gray-700">
        8.1. Kullanıcılar, kişisel bilgilerinin doğru olup olmadığını kontrol
        etme, güncelleme veya silme hakkına sahiptir. Bu haklarınızı kullanmak
        için bizimle <a href="mailto:info@lexgine.com">info@lexgine.com</a>{" "}
        adresi üzerinden iletişime geçebilirsiniz.
      </p>

      <h2 className="mb-2 font-display text-lg font-semibold text-gray-800">
        9. Politika Değişiklikleri
      </h2>
      <p className="mb-4 text-base leading-7 text-gray-700">
        9.1. Lexgine, bu Gizlilik ve Veri Koruma Politikası'nı herhangi bir
        zamanda güncelleme hakkını saklı tutar. Değişiklikler, güncellenmiş
        politikanın Hizmet üzerinde yayınlanmasıyla yürürlüğe girer.
        Değişikliklerden haberdar olmak için bu sayfayı düzenli olarak kontrol
        etmelisiniz.
      </p>

      <h2 className="mb-2 font-display text-lg font-semibold text-gray-800">
        10. İletişim
      </h2>
      <p className="mb-4 text-base leading-7 text-gray-700">
        Bu Gizlilik ve Veri Koruma Politikası ile ilgili herhangi bir sorunuz
        varsa, lütfen <a href="mailto:info@lexgine.com">info@lexgine.com</a>{" "}
        üzerinden bizimle iletişime geçin.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
