import React from 'react'; // Add this line
import { useRoutes } from "react-router-dom";
import routes from "./pages";
import "./assets/style/index.scss";
import { useEffect } from "react";
import { isDarkTheme } from "./utils/theme";
import api from "./services/api";
import { useAuth } from "./context/auth.context";
import { Analytics } from '@vercel/analytics/react';

function App() {
  const { user, setUser } = useAuth();

  useEffect(() => {
    const init = async () => {
      if (user) {
        await api.user
          .getProfile()
          .then(res => {
            const userData = res.data;
            userData.token = user.token;

            setUser(userData);
          })
          .catch(() => {
            setUser(null);
            window.location.reload();
          });
      }
    };

    if (isDarkTheme()) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
    init();
  }, []);

  return (
    <>
      <Analytics />
      {useRoutes(routes)}
    </>
  );
}

export default App;
