export const timeAgo = date => {
  const fromDate = new Date(date);
  const currentTime = new Date();
  const elapsed = currentTime - fromDate;

  const seconds = Math.floor(elapsed / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  if (years > 0) {
    return years + " yıl önce";
  } else if (months > 0) {
    return months + " ay önce";
  } else if (weeks > 0) {
    return weeks + " hafta önce";
  } else if (days > 0) {
    return days + " gün önce";
  } else if (hours > 0) {
    return hours + " saat önce";
  } else if (minutes > 0) {
    return minutes + " dakika önce";
  } else {
    return "bir kaç saniye önce";
  }
};

export const formatDate = date => {
  const d = new Date(date);
  const month = d.getMonth() + 1;
  const day = d.getDate();
  const year = d.getFullYear();

  return `${day}.${month}.${year}`;
};
