import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "context/auth.context";
import { Sidebar } from "../components";
import menu from "../constants/menu";
import { IconLayoutSidebar, IconX } from "@tabler/icons-react";
import { useScreenDetector } from "../hooks/use-screen-detector";

const ChatLayout = () => {
  const location = useLocation();
  const { user } = useAuth();
  const { isMobile } = useScreenDetector();
  const [isSidebarOpen, setIsSidebarOpen] = useState(!isMobile);
  const [isSidebarSmall, setIsSidebarSmall] = useState(false);

  let page = {
    isVisible: true,
    name: "Anasayfa",
    path: "/",
    mainPath: "/"
  };

  menu.map(item => {
    if (item.children) {
      const getPage = item.children.find(
        child => child.path === location.pathname
      );
      if (getPage) {
        page = getPage;
      }
      if (location.pathname.substring(0, 6) === "/chat/") {
        page.path = "/chat";
        page.mainPath = "/chat";
      }
    }
  });

  useEffect(() => {
    if (isMobile) {
      setIsSidebarSmall(false);
      setIsSidebarOpen(false);
    }
  }, [isMobile]);

  useEffect(() => {
    if (location.pathname.substring(0, 6) === "/chat/" && !isMobile) {
      setIsSidebarSmall(true);
    }
    if (isMobile) {
      setIsSidebarOpen(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (isSidebarOpen === false) {
      localStorage.setItem("isSidebarHidden", "true");
    } else {
      localStorage.removeItem("isSidebarHidden");
    }
  }, [isSidebarOpen]);

  if (!user) {
    return <Navigate to="/auth/welcome" replace={true} />;
  }

  return (
    <div className="flex h-screen bg-gray-25 dark:bg-gray-950">
      <div
        className={`z-10 flex border-gray-900 bg-gray-25 px-4 pb-10 pt-5 transition duration-500 md:fixed md:bottom-0 md:top-0 dark:bg-gray-950 ${isSidebarOpen ? "w-1/6 md:w-5/6" : "md:hidden md:w-0"} ${isSidebarSmall ? "w-20" : "w-1/6"}`}
      >
        <Sidebar activePage={page} isSidebarSmall={isSidebarSmall} />
        <div
          onClick={() => setIsSidebarOpen(prevState => !prevState)}
          className="bottom-0 right-0 top-0 hidden w-1/6 bg-gray-400 py-6 pl-4 text-white opacity-80 md:fixed md:block dark:bg-gray-800"
        >
          <IconX size={24} />
        </div>
      </div>
      <div
        className={`no-scrollbar my-3 mr-3 flex max-h-screen w-5/6 flex-col overflow-y-scroll rounded-xl bg-white shadow transition duration-500 md:ml-3 md:w-full dark:bg-gray-900 ${isSidebarSmall ? "w-full flex-1" : ""}`}
      >
        <div className={"flex"}>
          <div
            className="cursor-pointer px-4 py-3 text-gray-500 transition hover:text-gray-700 dark:text-gray-300 hover:dark:text-gray-200"
            onClick={() => {
              if (isMobile) {
                setIsSidebarOpen(prevState => !prevState);
              } else {
                setIsSidebarSmall(prevState => !prevState);
              }
            }}
          >
            <IconLayoutSidebar size={24} />
          </div>
        </div>
        <div className="no-scrollbar flex max-h-screen w-full flex-1 flex-col px-10 pb-10 md:px-5 md:pb-5">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default ChatLayout;
