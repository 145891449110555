import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ThreadItem, ThreadChatInput } from "../../components";
import { copyFn } from "../../utils/editor";
import api from "../../services/api";
import { streamFetch } from "../../utils/service";
import { useAuth } from "../../context/auth.context";
import { API_URL } from "../../config";

const Thread = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const location = useLocation();
  const [data, setData] = useState([{}]);
  const [newThread, setNewThread] = useState("");
  const [isLoading, setIsLoading] = useState([]);

  if (!id) {
    return;
  }

  useEffect(() => {
    const init = async () => {
      setIsLoading([true]);
      const userQuery = location.state?.data?.user_query;
      if (id === "new" && userQuery && !isLoading[0]) {
        setData([{ user_query: userQuery }]);
        let i = 0;
        await streamFetch(
          {
            url: `${API_URL}/chat/query`,
            method: "POST",
            token: user.token,
            body: {
              query: userQuery
            }
          },
          chunk => {
            setIsLoading([false]);
            if (
              !chunk ||
              !chunk.includes("data: ") ||
              chunk === "data: [DONE]" ||
              chunk === 'data: {"event": "DONE"}'
            ) {
              return;
            }
            const chunkData = JSON.parse(chunk.split("data: ")[1]);
            if (i === 0) {
              const threadId = chunkData.thread_id;
              window.history.pushState({}, "", `/chat/${threadId}`);
              setData(() => [
                {
                  ...chunkData,
                  user_query: userQuery,
                  assistant_response: ""
                }
              ]);
            } else {
              if (!chunkData.user_id) {
                setData(prevState => [
                  {
                    ...prevState[0],
                    assistant_response:
                      prevState[0].assistant_response +
                      chunkData.assistant_response
                  }
                ]);
              }
            }
            i++;
          }
        );
      } else {
        api.chat
          .getChatQuery({ threadId: id })
          .then(res => {
            setData(res.data.thread_details.filter(item => !item.edited));
            setIsLoading([false]);
          })
          .catch(err => {
            console.log("err", err);
          });
      }
    };

    init();
  }, [id]);

  useEffect(() => {
    document.body.addEventListener("copy", copyFn);

    return () => {
      document.body.removeEventListener("copy", copyFn);
    };
  }, []);

  const onAddNewThread = async threadData => {
    setIsLoading(prevState => [...prevState, true]);
    const { user_query } = threadData;
    const index = data.length;

    let i = 0;
    await streamFetch(
      {
        url: `${API_URL}/chat/query`,
        method: "POST",
        token: user.token,
        body: {
          query: user_query,
          thread_id: data[0].thread_id
        }
      },
      chunk => {
        setIsLoading(prevState => [...prevState.slice(0, index), false]);
        if (
          !chunk ||
          !chunk.includes("data: ") ||
          chunk === "data: [DONE]" ||
          chunk === 'data: {"event": "DONE"}'
        ) {
          return;
        }
        const chunkData = JSON.parse(chunk.split("data: ")[1]);
        if (i === 0) {
          setData(prevState => [
            ...prevState,
            {
              ...chunkData,
              user_query: user_query,
              assistant_response: ""
            }
          ]);
          setTimeout(() => {
            const getIndexElement = document.querySelector(
              `[data-index="${index}"]`
            );

            if (getIndexElement) {
              getIndexElement.scrollIntoView({
                behavior: "smooth",
                block: "start"
              });
            }
          }, 100);
        } else {
          if (!chunkData.user_id) {
            setData(prevState => [
              ...prevState.slice(0, index),
              {
                ...prevState[index],
                assistant_response:
                  prevState[index].assistant_response +
                  chunkData.assistant_response
              }
            ]);
          }
        }
        i++;
      }
    );
    setNewThread("");
  };

  const onUpdateQuery = async (title, index) => {
    setIsLoading(prevState => [...prevState.slice(0, index), false]);

    const threadId = data[index].thread_id;
    const responseId = data[index].response_id;

    let i = 0;
    await streamFetch(
      {
        url: `${API_URL}/chat/edit-query`,
        method: "POST",
        token: user.token,
        body: {
          thread_id: threadId,
          new_query: title,
          response_id: responseId
        }
      },
      chunk => {
        if (
          !chunk ||
          !chunk.includes("data: ") ||
          chunk === "data: [DONE]" ||
          chunk === 'data: {"event": "DONE"}'
        ) {
          return;
        }
        const chunkData = JSON.parse(chunk.split("data: ")[1]);
        if (i === 0) {
          setData(prevState => [
            ...prevState.slice(0, index),
            {
              ...chunkData,
              user_query: title,
              assistant_response: ""
            }
          ]);
        } else {
          if (!chunkData.user_id) {
            setData(prevState => [
              ...prevState.slice(0, index),
              {
                ...prevState[index],
                assistant_response:
                  prevState[index].assistant_response +
                  chunkData.assistant_response
              }
            ]);
          }
        }
        i++;
      }
    );
  };

  return (
    <div className="flex w-full flex-col items-center pb-20 md:pb-28">
      {data.map((item, index) => (
        <ThreadItem
          key={`thread-item-${index}`}
          className={index > 0 ? "border-t pt-8 dark:border-gray-800" : ""}
          threadData={item}
          addNewThread={title => setNewThread(title)}
          updateQuery={title => onUpdateQuery(title, index)}
          isLoading={isLoading[index]}
          index={index}
        />
      ))}
      <ThreadChatInput
        newThread={newThread}
        threadId={data[0] && data[0].thread_id}
        onSuccess={onAddNewThread}
      />
    </div>
  );
};

export default Thread;
