export const chatApi = api => ({
  chatQuery({ query, threadId }) {
    return api.post(
      "/chat/query",
      {
        query,
        thread_id: threadId
      },
      {
        responseType: "stream"
      }
    );
  },
  getChatQuery({ threadId }) {
    return api.get("/chat/thread-details?thread_id=" + threadId);
  },
  getThreadList() {
    return api.get("/chat/thread-history");
  },
  getRelatedThreads({ query }) {
    return api.post("/chat/related-queries", {
      query
    });
  },
  likeThread({ threadId, likeStatus, messageId }) {
    return api.post("/chat/like-answer", {
      thread_id: threadId,
      like: likeStatus,
      message_id: messageId
    });
  },
  editQuery({ threadId, query, responseId }) {
    return api.post("/chat/edit-query", {
      thread_id: threadId,
      new_query: query,
      response_id: responseId
    });
  }
});
