import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Input, Button } from "components";
import { emailPattern } from "constants/patterns";
import { useTranslation } from "react-i18next";
import api from "../../services/api";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");

  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm();

  const onSubmit = async submitData => {
    setIsLoading(true);
    await api.auth
      .forgotPassword({
        email: submitData.email
      })
      .then(() => {
        setMessage("Yeni şifreniz e-posta adresinize gönderildi.");
      })
      .catch(() => {
        setMessage("Bir hata oluştu. Lütfen tekrar deneyin.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className={"mx-auto mt-10 w-1/2 md:w-full md:px-5"}>
      <h1 className="mb-8 text-center font-display text-3xl font-bold text-gray-800">
        Şifrenizi mi unuttunuz?
      </h1>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <Input
          label={t("_auth.email")}
          name="email"
          error={errors.email}
          rules={{ required: true, pattern: emailPattern }}
          register={register}
          className="mb-6"
          placeholder={t("_auth.email")}
        />
        <Button type="submit" isLoading={isLoading}>
          {t("_auth.forgotPassword")}
        </Button>
      </form>
      {message && (
        <div className="mt-4 text-center text-sm font-medium text-green-700">
          {message}
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
