import { apiConfig } from "config";
import { authApi, chatApi, userApi } from "services";

const api = apiConfig;

api.auth = authApi(api);
api.user = userApi(api);
api.chat = chatApi(api);

api.content = {
    getMevzuatContent: (s3Url) => api.get('/content/mevzuat', { params: { s3_url_html: s3Url } }),
    getIctihatContent: (s3Url) => api.get('/content/ictihat', { params: { s3_url_json: s3Url } }),
  };

export default api;
