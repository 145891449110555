import React from "react";

const Terms = () => {
  return (
    <div className="flex flex-col py-10">
      <h1 className="mb-4 font-display text-2xl font-medium text-gray-950">
        Kullanım Şartları
      </h1>
      <h2 className="mb-2 font-display text-lg font-semibold text-gray-800">
        1. Giriş
      </h2>
      <p className="mb-4 text-base leading-7 text-gray-700">
        Bu KULLANIM ŞARTLARI, Lexgine ("Şirket") tarafından sağlanan Lexgine
        Yapay Zeka Asistanı ("Hizmet") için geçerlidir. Hizmeti kullanarak bu
        şartları kabul etmiş olursunuz. Bu şartları kabul etmiyorsanız, lütfen
        Hizmeti kullanmayınız.
      </p>

      <h2 className="mb-2 font-display text-lg font-semibold text-gray-800">
        2. Hizmet Tanımı
      </h2>
      <p className="mb-4 text-base leading-7 text-gray-700">
        Lexgine Yapay Zeka Asistanı, kullanıcılara yasal konularla ilgili
        çeşitli alanlarda yardımcı olan bir yapay zeka asistanıdır. Hizmet,
        hukuki belgelerin hazırlanması, yasal danışmanlık ve diğer yasal
        işlemlerle ilgili destek sağlar.
      </p>

      <h2 className="mb-2 font-display text-lg font-semibold text-gray-800">
        3. Sorumluluk Reddi
      </h2>
      <p className="mb-4 text-base leading-7 text-gray-700">
        3.1. Lexgine Yapay Zeka Asistanı, sağladığı tüm içerik ve çıktılar için
        hiçbir garanti vermemektedir. Sağlanan bilgiler ve sonuçlar tamamen
        öneri niteliğindedir ve herhangi bir yasal tavsiye veya kesin bilgi
        olarak kabul edilmemelidir.
      </p>
      <p className="mb-4 text-base leading-7 text-gray-700">
        3.2. Lexgine, Hizmetin kullanımı sonucunda oluşabilecek herhangi bir
        zarardan sorumlu değildir. Bu, doğrudan, dolaylı, özel, tesadüfi veya
        sonuç olarak meydana gelen zararları kapsar.
      </p>
      <p className="mb-4 text-base leading-7 text-gray-700">
        3.3. Hizmet, kullanıcıların girdilerine dayalı olarak çalışır ve
        kullanıcı girdilerinin doğruluğundan veya bütünlüğünden Şirket sorumlu
        değildir.
      </p>

      <h2 className="mb-2 font-display text-lg font-semibold text-gray-800">
        4. Kullanıcı Yükümlülükleri
      </h2>
      <p className="mb-4 text-base leading-7 text-gray-700">
        4.1. Hizmeti yasalara uygun şekilde kullanmalısınız.
      </p>
      <p className="mb-4 text-base leading-7 text-gray-700">
        4.2. Hizmeti kullanırken yanlış veya yanıltıcı bilgi girmemelisiniz.
      </p>
      <p className="mb-4 text-base leading-7 text-gray-700">
        4.3. Hizmeti kullanırken diğer kullanıcıların veya üçüncü şahısların
        haklarını ihlal etmemelisiniz.
      </p>

      <h2 className="mb-2 font-display text-lg font-semibold text-gray-800">
        5. Gizlilik ve Veri Koruma
      </h2>
      <p className="mb-4 text-base leading-7 text-gray-700">
        5.1. Kullanıcı bilgileri, Lexgine Gizlilik Politikası uyarınca işlenir.
        Hizmeti kullanarak bu politikayı kabul etmiş sayılırsınız.
      </p>
      <p className="mb-4 text-base leading-7 text-gray-700">
        5.2. Hizmetin işleyişi sırasında toplanan veriler, yalnızca Hizmetin
        iyileştirilmesi amacıyla kullanılacaktır.
      </p>

      <h2 className="mb-2 font-display text-lg font-semibold text-gray-800">
        6. Hizmetin Kullanılabilirliği ve Kesintiler
      </h2>
      <p className="mb-4 text-base leading-7 text-gray-700">
        6.1. Lexgine, Hizmetin sürekli ve kesintisiz olarak sunulacağını garanti
        etmez. Teknik sorunlar veya bakım çalışmaları nedeniyle Hizmette
        kesintiler yaşanabilir.
      </p>
      <p className="mb-4 text-base leading-7 text-gray-700">
        6.2. Şirket, Hizmetin herhangi bir zamanda ve önceden bildirimde
        bulunmaksızın değiştirilmesi, askıya alınması veya sonlandırılması
        hakkını saklı tutar.
      </p>

      <h2 className="mb-2 font-display text-lg font-semibold text-gray-800">
        7. Fikri Mülkiyet Hakları
      </h2>
      <p className="mb-4 text-base leading-7 text-gray-700">
        7.1. Hizmet ve içeriği, Lexgine veya lisans verenlerine ait fikri
        mülkiyet haklarıyla korunmaktadır. Hizmetin herhangi bir kısmını
        kopyalayamaz, değiştiremez veya yeniden dağıtamazsınız.
      </p>

      <h2 className="mb-2 font-display text-lg font-semibold text-gray-800">
        8. Değişiklikler
      </h2>
      <p className="mb-4 text-base leading-7 text-gray-700">
        Lexgine, bu Kullanım Şartları'nı herhangi bir zamanda güncelleme hakkını
        saklı tutar. Değişiklikler, güncellenmiş şartların Hizmet üzerinde
        yayınlanmasıyla yürürlüğe girer. Değişikliklerden haberdar olmak için bu
        sayfayı düzenli olarak kontrol etmelisiniz.
      </p>

      <h2 className="mb-2 font-display text-lg font-semibold text-gray-800">
        9. Uygulanacak Hukuk ve Yargı Yetkisi
      </h2>
      <p className="mb-4 text-base leading-7 text-gray-700">
        Bu Kullanım Şartları, Türkiye Cumhuriyeti kanunlarına tabidir. Bu
        şartlardan doğan her türlü uyuşmazlıkta İstanbul mahkemeleri yetkilidir.
      </p>

      <h2 className="mb-2 font-display text-lg font-semibold text-gray-800">
        10. İletişim
      </h2>
      <p className="text-base leading-7 text-gray-700">
        Bu Kullanım Şartları ile ilgili herhangi bir sorunuz varsa, lütfen{" "}
        <a href="mailto:info@lexgine.com">info@lexgine.com</a> üzerinden bizimle
        iletişime geçin.
      </p>
    </div>
  );
};

export default Terms;
