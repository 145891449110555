import React from "react";
import { ChatInput } from "../index";

const ThreadChatInputComponent = ({ newThread, threadId, onSuccess }) => {
  return (
    <div className="fixed bottom-10 flex w-full max-w-4xl gap-10 md:left-3 md:right-3 md:w-auto">
      <div className={"flex-1"}>
        <ChatInput
          newThread={newThread}
          threadId={threadId}
          onSuccess={onSuccess}
        />
      </div>
      <div className="w-64 md:hidden"></div>
    </div>
  );
};

export default ThreadChatInputComponent;
