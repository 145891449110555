import {
  IconArrowRight,
  IconChevronDown,
  IconChevronUp,
  IconClipboard,
  IconEdit,
  IconThumbDown,
  IconThumbUp
} from "@tabler/icons-react";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { Button } from "../index";
import api from "../../services/api";
import Markdown from "react-markdown";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const ThreadItem = ({
  className,
  threadData,
  addNewThread,
  updateQuery,
  isLoading,
  index
}) => {
  const ref = React.useRef(null);
  const [isEdit, setEdit] = useState(false);
  const [title, setTitle] = useState(threadData.user_query);
  const [value, setValue] = useState(threadData.user_query);
  const [isLiked, setIsLiked] = useState(null);
  const [relatedThreads, setRelatedThreads] = useState([]);
  const [isLoadingMevzuat, setIsLoadingMevzuat] = useState(false);
  const [isLoadingIctihat, setIsLoadingIctihat] = useState(false);

  const [tabs, setTabs] = React.useState({
    laws: true,
    jurisprudence: true
  });

  const copy = async () => {
    if (ref.current) {
      const range = document.createRange();
      range.selectNode(ref.current);

      const selection = window.getSelection();

      if (selection) {
        selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand("copy");
        selection.removeAllRanges();
      }
    }
  };

  const saveTitle = () => {
    setTitle(value);
    setEdit(false);
    updateQuery(value);
  };

  useEffect(() => {
    if (!isEdit && title !== value) {
      setValue(title);
    }
  }, [isEdit]);

  useEffect(() => {
    const init = async () => {
      api.chat
        .getRelatedThreads({
          query: threadData.user_query
        })
        .then(res => {
          setRelatedThreads(res.data.related_queries || []);
        });
    };
    threadData.user_query && init();

    setValue(threadData.user_query);
    setTitle(threadData.user_query);
  }, [threadData.user_query]);

  const toggleLike = async isLike => {
    setIsLiked(prevState => (prevState === isLike ? null : isLike));
    api.chat.likeThread({
      threadId: threadData.thread_id,
      likeStatus: isLike,
      messageId: threadData.query_id
    });
  };

  const handleMevzuatClick = useCallback(async s3Url => {
    setIsLoadingMevzuat(true);
    try {
      const response = await api.content.getMevzuatContent(s3Url);
      const htmlContent = response.data.content;

      const newWindow = window.open("", "_blank");
      if (newWindow) {
        newWindow.document.write(htmlContent);
        newWindow.document.close();
      } else {
        alert("Please allow popups for this website to view the content.");
      }
    } catch (error) {
      console.error("Error fetching mevzuat content:", error);
      let errorMessage =
        "An error occurred while fetching the content. Please try again.";
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }
      alert(errorMessage);
    } finally {
      setIsLoadingMevzuat(false);
    }
  }, []);

  const handleIctihatClick = useCallback(async s3Url => {
    setIsLoadingIctihat(true);
    try {
      const response = await api.content.getIctihatContent(s3Url);
      const { kararMetni, daire, kararTarihi, esasNo, kararNo } = response.data;

      const newWindow = window.open("", "_blank");
      if (newWindow) {
        newWindow.document.write(`
          <html>
            <head>
              <title>İçtihat: ${daire} - ${kararTarihi}</title>
              <style>
                body { font-family: Arial, sans-serif; line-height: 1.6; padding: 20px; }
                h1 { color: #333; }
                .meta { background-color: #f0f0f0; padding: 10px; margin-bottom: 20px; }
                .content { white-space: pre-wrap; }
              </style>
            </head>
            <body>
              <h1>İçtihat Detayları</h1>
              <div class="meta">
                <p><strong>Daire:</strong> ${daire}</p>
                <p><strong>Karar Tarihi:</strong> ${kararTarihi}</p>
                <p><strong>Esas No:</strong> ${esasNo}</p>
                <p><strong>Karar No:</strong> ${kararNo}</p>
              </div>
              <div class="content">${kararMetni}</div>
            </body>
          </html>
        `);
        newWindow.document.close();
      } else {
        alert("Please allow popups for this website to view the content.");
      }
    } catch (error) {
      console.error("Error fetching ictihat content:", error);
      let errorMessage =
        "An error occurred while fetching the content. Please try again.";
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }
      alert(errorMessage);
    } finally {
      setIsLoadingIctihat(false);
    }
  }, []);

  return (
    <div
      ref={ref}
      data-index={index}
      className={`flex min-h-screen w-full max-w-4xl gap-10 md:flex-col ${className}`}
    >
      <div className="min-h-full flex-1">
        {isEdit ? (
          <div className="mb-4 flex w-full flex-col gap-2">
            <div className="flex w-full items-center rounded-lg border-4 border-gray-200 bg-white p-3 dark:border-gray-700 dark:bg-gray-950">
              <textarea
                rows={1}
                value={value}
                onChange={e => setValue(e.target.value)}
                className="no-scrollbar flex-1 resize-none text-base outline-none dark:bg-gray-950 dark:text-white"
                placeholder="Herhangi bir şey sor"
              />
            </div>
            <div className={"flex justify-end gap-3"}>
              <Button
                isFullWidth={false}
                variant="secondary"
                className="hover:bg-primary-600 hover:text-white"
                onClick={() => setEdit(false)}
              >
                Vazgeç
              </Button>
              <Button isFullWidth={false} onClick={saveTitle}>
                Kaydet
              </Button>
            </div>
          </div>
        ) : (
          <div className="mb-4 font-display text-2xl font-medium text-gray-800 dark:text-white">
            {threadData.user_query || (
              <div className={"animate-pulse"}>
                <div className="h-10 w-full rounded bg-gray-100 dark:bg-gray-700"></div>
              </div>
            )}
          </div>
        )}
        {isLoading ? (
          <div className="flex items-center justify-center">
            <div className="flex w-full animate-pulse gap-10">
              <div className="flex w-full flex-col gap-6">
                <div className="min-h-96 w-full rounded bg-gray-100 dark:bg-gray-700"></div>
                <div className="min-h-10 w-full rounded bg-gray-100 dark:bg-gray-700"></div>
                <div className="min-h-32 w-full rounded bg-gray-100 dark:bg-gray-700"></div>
              </div>
              <div className="flex w-64 flex-col gap-6">
                <div className="min-h-52 w-64 min-w-64 rounded bg-gray-100 dark:bg-gray-700"></div>
                <div className="min-h-52 w-64 min-w-64 rounded bg-gray-100 dark:bg-gray-700"></div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="hidden w-full md:block">
              <div
                onClick={() =>
                  setTabs(prevState => ({
                    ...prevState,
                    laws: !prevState.laws
                  }))
                }
                className="mt-2 flex cursor-pointer items-center justify-between font-display text-base font-medium text-gray-600 dark:text-gray-300"
              >
                İlgili Mevzuat
                {tabs.laws ? (
                  <IconChevronDown size={20} />
                ) : (
                  <IconChevronUp size={20} />
                )}
              </div>
              {tabs.laws && (
                <div className="no-scrollbar mt-2 flex gap-2 overflow-scroll">
                  {threadData.mevzuat_display?.map((item, index) => (
                    <div
                      key={`mevzuat-mobile-${index}`}
                      className="w-32 min-w-32 cursor-pointer rounded-lg border bg-white p-2 transition hover:bg-gray-25 dark:border-gray-700 dark:bg-gray-800 hover:dark:bg-gray-900"
                      onClick={() => handleMevzuatClick(item.s3_url_html)}
                    >
                      <div className="text-sm text-gray-700 dark:text-gray-300">
                        {item.mevzuat_adi}
                      </div>
                      <span className="text-xs text-gray-500 dark:text-gray-400">
                        {item.mevzuat_no}
                      </span>
                      {isLoadingMevzuat && (
                        <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-50 dark:bg-gray-800 dark:bg-opacity-50">
                          <svg
                            className="h-5 w-5 animate-spin text-primary-600"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
              <div
                onClick={() =>
                  setTabs(prevState => ({
                    ...prevState,
                    jurisprudence: !prevState.jurisprudence
                  }))
                }
                className="mt-4 flex cursor-pointer items-center justify-between font-display text-base font-medium text-gray-600 dark:text-gray-300"
              >
                İlgili İçtihat
                {tabs.jurisprudence ? (
                  <IconChevronDown size={20} />
                ) : (
                  <IconChevronUp size={20} />
                )}
              </div>
              {tabs.jurisprudence && (
                <div className="no-scrollbar mt-2 flex gap-2 overflow-scroll">
                  {threadData.ictihat_display?.map((item, index) => (
                    <div
                      className="w-48 min-w-48 cursor-pointer rounded-lg border bg-white p-2 transition hover:bg-gray-25 dark:border-gray-700 dark:bg-gray-800 hover:dark:bg-gray-900"
                      key={`ictihat-mobile-${index}`}
                      onClick={() => handleIctihatClick(item.s3_url_json)}
                    >
                      <div className="text-sm text-gray-700 dark:text-gray-300">
                        {item.daire}
                      </div>
                      <div className="mt-1.5 text-xs text-gray-500 dark:text-gray-400">
                        {item.karar_tarihi}
                      </div>
                      <span className="text-xs text-gray-500 dark:text-gray-400">
                        {item.esas_no} E., {item.karar_no} K.
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <article
              className="prose prose-base prose-gray leading-6 dark:prose-invert prose-p:mb-0 prose-ol:mt-0 prose-ol:flex prose-ol:flex-col md:mt-6"
              ref={ref}
            >
              <Markdown>{threadData.assistant_response}</Markdown>
            </article>
            <div className="mt-4 flex w-full justify-between">
              <div className={`flex gap-1`}>
                <span
                  onClick={() => toggleLike(true)}
                  className={`flex h-10 w-10 cursor-pointer items-center justify-center rounded-full transition duration-300 ease-in-out hover:bg-gray-50 hover:text-gray-700 hover:dark:bg-gray-800 hover:dark:text-gray-300 ${isLiked ? "text-primary-600" : "text-gray-400 dark:text-gray-500"}`}
                >
                  <IconThumbUp size={20} />
                </span>
                <span
                  onClick={() => toggleLike(false)}
                  className={`flex h-10 w-10 cursor-pointer items-center justify-center rounded-full transition duration-300 ease-in-out hover:bg-gray-50 hover:text-gray-700 hover:dark:bg-gray-800 hover:dark:text-gray-300 ${isLiked === false ? "text-primary-600" : "text-gray-400 dark:text-gray-500"}`}
                >
                  <IconThumbDown size={20} />
                </span>
              </div>
              <div className="flex gap-1 text-gray-400 dark:text-gray-500">
                <span
                  className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full transition duration-300 ease-in-out hover:bg-gray-50 hover:text-gray-700 hover:dark:bg-gray-800 hover:dark:text-gray-300"
                  onClick={copy}
                >
                  <IconClipboard size={20} />
                </span>
                <span
                  className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full transition duration-300 ease-in-out hover:bg-gray-50 hover:text-gray-700 hover:dark:bg-gray-800 hover:dark:text-gray-300"
                  onClick={() => setEdit(prevState => !prevState)}
                >
                  <IconEdit size={20} />
                </span>
              </div>
            </div>
            <div
              className={
                "mt-4 flex flex-col border-t border-gray-400 pt-6 dark:border-gray-800"
              }
            >
              <div
                className={
                  "mb-2 font-display text-xl font-bold text-gray-800 dark:text-gray-300"
                }
              >
                Benzer Sorgular:
              </div>
              <div className="mb-8 flex flex-col gap-1">
                {relatedThreads.map((item, index) => (
                  <div
                    key={`related-threads-${index}`}
                    onClick={() => addNewThread(item)}
                    className="flex cursor-pointer justify-between gap-1 py-1 text-base text-gray-500 transition duration-300 hover:text-gray-950 dark:text-gray-500 hover:dark:text-gray-300"
                  >
                    {item}
                    <div>
                      <IconArrowRight size={20} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
      {!isLoading && (
        <div className="max-h-full w-64 md:hidden">
          <div
            onClick={() =>
              setTabs(prevState => ({
                ...prevState,
                jurisprudence: !prevState.jurisprudence
              }))
            }
            className="mt-4 flex cursor-pointer items-center justify-between font-display text-base font-medium text-gray-600 dark:text-gray-300"
          >
            İlgili İçtihat
            {tabs.jurisprudence ? (
              <IconChevronDown size={20} />
            ) : (
              <IconChevronUp size={20} />
            )}
          </div>
          {tabs.jurisprudence && (
            <div
              className={
                "no-scrollbar mt-2 flex max-h-96 flex-col gap-2 overflow-scroll"
              }
            >
              {threadData.ictihat_display?.map((item, index) => (
                <div
                  className="cursor-pointer rounded-lg border bg-white p-2 transition hover:bg-gray-25 dark:border-gray-700 dark:bg-gray-800 hover:dark:bg-gray-900"
                  key={`ictihat-desktop-${index}`}
                  onClick={() => handleIctihatClick(item.s3_url_json)}
                >
                  <div className="text-sm text-gray-700 dark:text-gray-300">
                    {item.daire}
                  </div>
                  <div className="mt-1.5 text-xs text-gray-500 dark:text-gray-400">
                    {item.karar_tarihi}
                  </div>
                  <span className="text-xs text-gray-500 dark:text-gray-400">
                    {item.esas_no} E., {item.karar_no} K.
                  </span>
                </div>
              ))}
            </div>
          )}
          <div
            onClick={() =>
              setTabs(prevState => ({ ...prevState, laws: !prevState.laws }))
            }
            className="mt-2 flex cursor-pointer items-center justify-between font-display text-base font-medium text-gray-600 dark:text-gray-300"
          >
            İlgili Mevzuat
            {tabs.laws ? (
              <IconChevronDown size={20} />
            ) : (
              <IconChevronUp size={20} />
            )}
          </div>
          {tabs.laws && (
            <div className="no-scrollbar mt-2 flex max-h-96 flex-col gap-2 overflow-scroll">
              {threadData.mevzuat_display?.map((item, index) => (
                <div
                  key={`mevzuat-desktop-${index}`}
                  className="cursor-pointer rounded-lg border bg-white p-2 transition hover:bg-gray-25 dark:border-gray-700 dark:bg-gray-800 hover:dark:bg-gray-900"
                  onClick={() => handleMevzuatClick(item.s3_url_html)}
                >
                  <div className="text-sm text-gray-700 dark:text-gray-300">
                    {item.mevzuat_adi}
                  </div>
                  <span className="text-xs text-gray-500 dark:text-gray-400">
                    {item.mevzuat_no}
                  </span>
                  {isLoadingMevzuat && (
                    <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-50 dark:bg-gray-800 dark:bg-opacity-50">
                      <svg
                        className="h-5 w-5 animate-spin text-primary-600"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ThreadItem;
