import React, { useEffect, useState } from "react";
import menu from "constants/menu";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "context/auth.context";
import { IconLogout, IconPencil, IconUser } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import Sun from "../../icons/Sun";
import Moon from "../../icons/Moon";
import { isDarkTheme } from "../../utils/theme";
import { Button, ChatModal } from "../index";
import api from "../../services/api";

const Sidebar = ({ activePage, isSidebarSmall }) => {
  const { user, setUser } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [theme, setTheme] = useState(isDarkTheme() ? "dark" : "light");
  const [chatModal, setChatModal] = useState(false);

  const signOut = () => {
    setUser(false);
    localStorage.removeItem("user");
    api.auth.logout().then().catch();
  };

  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
      localStorage.theme = "dark";
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.theme = "light";
    }
  }, [theme]);

  const ThemeIcon = theme === "dark" ? Sun : Moon;

  return (
    <>
      <div
        className={`flex w-full flex-col justify-between ${isSidebarSmall ? "items-center" : ""}`}
      >
        <img
          src={isSidebarSmall ? "/lexgine-logo.svg" : "/logo.svg"}
          className="mb-2"
          width={isSidebarSmall ? 40 : 144}
        />
        <div
          className={`mt-6 flex w-full flex-1 flex-col ${isSidebarSmall ? "items-center" : ""}`}
        >
          <Button
            variant="secondary"
            onClick={() => navigate("/")}
            className={`flex gap-1 rounded-full hover:bg-primary-600 hover:text-white ${isSidebarSmall ? "px-0-important w-10 max-w-10" : ""}`}
          >
            {!isSidebarSmall && (
              <span className={"text-base font-medium"}>
                {t("_sidebar.newChat")}
              </span>
            )}
            <IconPencil size={16} stroke={3} />
          </Button>
          {menu.map((item, index) =>
            item.children && item.isVisible ? (
              <div key={index} className={"mt-6 flex flex-col gap-2"}>
                {item.children.map(
                  (child, index) =>
                    child.isVisible && (
                      <div
                        onClick={() => child.name === "Akıllı asistan" ? navigate("/") : navigate(child.path)}
                        key={index}
                        className={`flex cursor-pointer items-center gap-3 rounded-full text-gray-600 transition-all hover:bg-white dark:text-gray-300 hover:dark:text-gray-700 ${
                          activePage.mainPath === child.mainPath
                            ? "bg-white dark:text-gray-700"
                            : ""
                        } ${isSidebarSmall ? "h-10 w-10 justify-center" : "p-2 px-4"}`}
                      >
                        <child.Icon
                          size={24}
                          stroke={1.5}
                          color="currentColor"
                        ></child.Icon>
                        {!isSidebarSmall && (
                          <span className="font-medium">{child.name}</span>
                        )}
                      </div>
                    )
                )}
              </div>
            ) : (
              <div key={index}></div>
            )
          )}
        </div>
        <div className="flex flex-col gap-1">
          <div
            onClick={() =>
              setTheme(prevState => (prevState === "dark" ? "light" : "dark"))
            }
            className={`flex cursor-pointer items-center gap-4 rounded-full text-sm text-gray-600 transition-all hover:bg-white dark:text-gray-300 hover:dark:text-gray-700 ${isSidebarSmall ? "ml-1 h-8 w-8 justify-center" : "px-3 py-1.5"}`}
          >
            <ThemeIcon width="18px" height="18px" />
            {!isSidebarSmall && (
              <span className={"font-medium"}>
                {t(`_sidebar.${theme === "dark" ? "light" : "dark"}Theme`)}
              </span>
            )}
          </div>
          <div
            className={`flex cursor-pointer items-center gap-3 rounded-full text-sm text-gray-600 transition-all hover:bg-white dark:text-gray-300 hover:dark:text-gray-700 ${isSidebarSmall ? "ml-1.5 h-8 w-8 justify-center" : "px-3 py-1.5"}`}
            onClick={signOut}
          >
            <IconLogout size={22} stroke={1.5} color="currentColor" />
            {!isSidebarSmall && (
              <span className={"font-medium"}>Çıkış Yap</span>
            )}
          </div>
          <div className="my-3 border-t border-gray-200 dark:border-gray-700"></div>
          <div
            onClick={() => navigate("/profile")}
            className={`hover:text-blue-600 flex cursor-pointer items-center gap-3 rounded-full text-sm text-gray-600 transition-all hover:bg-white dark:text-gray-300 hover:dark:text-gray-700 ${isSidebarSmall ? "ml-1.5 h-8 w-8 justify-center" : "px-3 py-1.5"}`}
          >
            <IconUser size={22} stroke={1.5} color="currentColor" />
            {!isSidebarSmall && (
              <span className={"font-medium"}>
                {user.given_name} {user.family_name}
              </span>
            )}
          </div>
        </div>
      </div>
      <ChatModal closeModal={() => setChatModal(false)} isOpen={chatModal} />
    </>
  );
};

export default Sidebar;
